var graphsCreated = false
var growthChart = document.getElementById('growth-chart')

new WOW().init()

function scroll () {
  if (!graphsCreated && growthChart.getBoundingClientRect().top <= 600) {
    createGraphs()
    graphsCreated = true
  }
}

function createGraphs () {
  var marketGrowth = {
    labels: [2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    datasets: [
      {
        fillColor: 'rgba(219, 203, 132, 0.7)',
        strokeColor: 'white',
        pointColor: '#9F4545',
        label: 'Amount Loaded onto Cards ($Billions)',
        data: [119, 141, 159, 179, 188, 198, 231, 263, 299, 309, 317, 326, 334, 343]
      }
    ]
  }

  var marketGrowthOptions = {
    scaleFontColor: 'rgba(255, 255, 255, 0.8)',
    scaleLineColor: 'white',
    scaleLabel: '$<%=value%>B',
    tooltipTemplate: '<%= label %>: $<%= value %>B'
  }

  var lineCtx = document.getElementById('growth-chart').getContext('2d')
  new Chart(lineCtx).Line(marketGrowth, marketGrowthOptions)

  var demographic = [
    {
      value: 45,
      label: 'Millenials (18-32)',
      color: '#9F4545'
    },
    {
      value: 35,
      label: 'Generation X (33-48)',
      color: '#DACB84'
    },
    {
      value: 18,
      label: 'Baby boomeres (49-67)',
      color: '#A4BD73'
    },
    {
      value: 4,
      label: 'Seniors (68+)',
      color: '#6CD5C2'
    }
  ]

  var pieOptions = {
    tooltipTemplate: '<%= label %>: <%= value %>%'
  }

  var pieCtx = document.getElementById('demographic-chart').getContext('2d')
  new Chart(pieCtx).Pie(demographic, pieOptions)

  var use = [
    {
      value: 47,
      label: 'Ages 18-24',
      color: '#9F4545'
    },
    {
      value: 28,
      label: 'Ages 25-44',
      color: '#DACB84'
    },
    {
      value: 19,
      label: 'Ages 45-64',
      color: '#A4BD73'
    },
    {
      value: 6,
      label: 'Ages 65+',
      color: '#6CD5C2'
    }
  ]

  var useCtx = document.getElementById('use-chart').getContext('2d')
  new Chart(useCtx).Pie(use, pieOptions)
}

window.onscroll = scroll
scroll()

/* global WOW, Chart */
